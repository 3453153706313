<template>
  <div class="app">
    <AppHeader fixed>
      <SidebarToggler class="d-lg-none" display="md" mobile />
      <b-link class="navbar-brand" to="#">
        <img
          class="navbar-brand-full"
          src="/img/brand/baslik_ortali.png"
          height="20"
          alt="Ekonomist Logo"
          width="70"
        />
        <img
          class="navbar-brand-minimized"
          src="/img/brand/baslik_ortali.png"
          width="30"
          height="30"
          alt="Ekonomist Logo"
        />
      </b-link>

      <SidebarToggler class="d-md-down-none" display="lg" :defaultOpen="true" />
      <b-navbar-nav class="d-md-down-none"></b-navbar-nav>
      <b-navbar-nav class="ml-auto">
        <DefaultHeaderDropdownAccnt />
      </b-navbar-nav>
    </AppHeader>
    <div class="app-body">
      <AppSidebar fixed>
        <SidebarHeader />
        <SidebarForm />
        <SidebarNav :navItems="nav"></SidebarNav>
        <SidebarFooter />
        <SidebarMinimizer />
      </AppSidebar>
      <main class="main">
        <div
          id="custom-loading-bar"
        >
        <div>
          <img
            src="/img/ajax-loader.gif"
            alt="bekleyin ..."
          />
          <br />
          <h6>{{$t('wait')}}</h6>
          </div>
        </div>
        <Breadcrumb :list="list" />
        <div class="container-fluid">
          <router-view></router-view>
        </div>
      </main>
    </div>
    <TheFooter>
      <!--footer-->
      <div>
        <a href="https://ekonomist.com/">Ekonomist</a>
        <span class="ml-1">&copy; 2020 Her hakkı saklıdır.</span>
      </div>
      <div class="ml-auto">
        <span class="mr-1">Powered by</span>
        <a href="https://ekonomist.com/">Ekonomist Dev Team</a>
      </div>
    </TheFooter>
  </div>
</template>

<script>
import navAdmin from "@/navs/navAdmin";
import navUser from "@/navs/navUser";
import jwtHelper from "../shared/jwt-helper";
import Router from "../router/index";
import {
  Header as AppHeader,
  SidebarToggler,
  Sidebar as AppSidebar,
  SidebarFooter,
  SidebarForm,
  SidebarHeader,
  SidebarMinimizer,
  SidebarNav,
  Aside as AppAside,
  AsideToggler,
  Footer as TheFooter,
  Breadcrumb,
} from "@coreui/vue";
import DefaultHeaderDropdownAccnt from "./DefaultHeaderDropdownAccnt";
import authorize from "../shared/authorization/authorize";
export default {
  name: "DefaultContainer",
  components: {
    AsideToggler,
    AppHeader,
    AppSidebar,
    AppAside,
    TheFooter,
    Breadcrumb,
    DefaultHeaderDropdownAccnt,
    SidebarForm,
    SidebarFooter,
    SidebarToggler,
    SidebarHeader,
    SidebarNav,
    SidebarMinimizer,
  },
  computed: {
    name() {
      return this.$route.name;
    },
    list() {
      return this.$route.matched.filter(
        (route) => route.name || route.meta.label
      );
    },
    nav() {
      let allRouteList = this.$router.options.routes.filter(
        (p) => p.redirect == "/dashboard"
      )[0].children;
      let validNavList = [];
      navAdmin.items.forEach((element) => {
        const checkRoute = allRouteList.filter(
          (p) => p.redirect == element.url || p.path == element.url
        );
        if (checkRoute.length > 0) {
          const routeItem = checkRoute[0];
          if (routeItem.meta !== undefined) {
            const component = routeItem.meta.component.name;
            const action = routeItem.meta.action;
            const check = authorize.hasAuth(component, action);
            if (authorize.hasAuth(component, action)) {
              validNavList.push(element);
            }
          } else {
            validNavList.push(element);
          }
        }
      });
      return validNavList; // navAdmin.items; //jwtHelper.isAdmin() ? navAdmin.items : navUser.items;
    },
  },
};
</script>
<style scoped>
#custom-loading-bar{
	display: none;
	position: fixed;
	top: 0;
	text-align: center;
	left: 0px;
	right: 0px;
	margin: auto;
	z-index: 99;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
}
#custom-loading-bar> div {
	background: #fff;
	width: 500px;
	border-radius: 30px;
	margin-top: 10%;
	text-align: center;
	margin: auto;
	margin-top: 10%;
	padding: 20px;
}
#custom-loading-bar > div > img {
	border-radius: 30px;
	margin: auto;
	height: 200px;
	width: 100%;
}
</style>