import { getTranslation } from '../shared/language-helper';
export default {
  items: [
    {
      name: getTranslation('home'),
      url: "/",
      icon: "icon-home"
    },
    {
      title: true,
      name: getTranslation('category'),
      class: "",
      wrapper: {
        element: "",
        attributes: {}
      }
    },
    {
      name: getTranslation('newCategory'),
      url: "/category-add",
      icon: "icon-plus"
    },
    {
      name: getTranslation('categories'),
      url: "/category-list",
      icon: "icon-list"
    },
    {
      title: true,
      name: getTranslation('user'),
      class: "",
      wrapper: {
        element: "",
        attributes: {}
      }
    },
    {
      name: getTranslation('users'),
      url: "/user-list",
      icon: "fa fa-address-card"
    },
    {
      title: true,
      name: getTranslation('subject'),
      class: "",
      wrapper: {
        element: "",
        attributes: {}
      }
    },
    {
      name: getTranslation('newSubject'),
      url: "/subject-add",
      icon: "fa fa-file-word-o"
    },
    {
      name: getTranslation('subjects'),
      url: "/subject-list",
      icon: "fa fa-book"
    },
    {
      title: true,
      name: getTranslation('comment'),
      class: "",
      wrapper: {
        element: "",
        attributes: {}
      }
    },
    {
      name: getTranslation('comments'),
      url: "/comment-list",
      icon: "icon-list"
    },
    {
      title: true,
      name: getTranslation('reports'),
      class: "",
      wrapper: {
        element: "",
        attributes: {}
      }
    },
    {
      name: getTranslation('subjectReports'),
      url: "/subject-report-list",
      icon: "fa fa-file-text-o"
    },
    {
      name: getTranslation('commentReports'),
      url: "/comment-report-list",
      icon: "fa fa-comments-o"
    },
    {
      name: getTranslation('commentReplyReports'),
      url: "/comment-reply-report-list",
      icon: "fa fa-comments"
    },
    {
      name: getTranslation('logs'),
      url: '/log-list',
      icon: 'fa fa-history'
    }
  ]
}
