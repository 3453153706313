<template>
  <AppHeaderDropdown right no-caret>
    <template slot="header">
      <span>
        <strong v-if="!$isMobile()" style="margin-right: -15px">{{
          userName
        }}</strong>
        <i class="fa fa-user userInfo"></i>
      </span>
    </template>
    <template slot="dropdown">
      <b-dropdown-header tag="div" class="text-center">
        <strong v-if="$isMobile()"> {{ userName }}</strong>
        <strong v-else> {{ $t("account") }}</strong>
      </b-dropdown-header>
      <b-dropdown-item @click="updateProfile">
        <i class="fa fa-user-circle" /> {{ $t("userProfile") }}
      </b-dropdown-item>
      <b-dropdown-item @click="changePassword">
        <i class="fa fa-key" /> {{ $t("resetPassword") }}
      </b-dropdown-item>
      <b-dropdown-divider />
      <b-dropdown-item @click="logout">
        <i class="fa fa-sign-out" /> {{ $t("exit") }}
      </b-dropdown-item>
    </template>
  </AppHeaderDropdown>
</template>

<script>
import { HeaderDropdown as AppHeaderDropdown } from "@coreui/vue";
import jwtHelper from "../shared/jwt-helper";
import router from "../router/index.js";
export default {
  name: "DefaultHeaderDropdownAccnt",
  components: {
    AppHeaderDropdown,
  },
  methods: {
    changeLanguage() {
      router.push({ path: "/change-language" });
    },
    updateProfile() {
      router.push({ path: "/user-profile" });
    },
    changePassword() {
      router.push({ path: "/change-password" });
    },
    logout() {
      localStorage.removeItem("token");
      localStorage.removeItem("expiration");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("cultureName");
      localStorage.removeItem("currencyCode");
      router.push({ path: "/login" });
    },
  },
  computed: {
    userName() {
      return jwtHelper.getUserName().toUpperCase();
    },
  },
};
</script>

<style>
.userInfo {
  font-size: 21px;
  margin: auto 25px;
  width: 30px;
  height: 30px;
  background: #d8d3d3;
  border-radius: 50%;
  padding: 3px;
}
</style>
