import { getTranslation } from '../shared/language-helper';
export default {
  items: [
    {
      name: getTranslation('home'),
      url: "/",
      icon: "icon-home"
    },
    {
      title: true,
      name: getTranslation('news'),
      class: "",
      wrapper: {
        element: "",
        attributes: {}
      }
    },

    {
      name: getTranslation('addNews'),
      url: "/news-add",
      icon: "fa fa-plus-square"
    },
    {
      name: getTranslation('news'),
      url: "/news-list",
      icon: "fa fa-newspaper-o"
    },
    {
      name: getTranslation('newsOrders'),
      url: "/news-orders",
      icon: "fa fa-sort-amount-asc"
    },
    {
      name: getTranslation('newsAgenciesList'),
      url: "/news-agencies-list",
      icon: "fa fa-id-card-o"
    },
    // {
    //   name: getTranslation('newsComments'),
    //   url: "/news-comments",
    //   icon: "fa fa-comments"
    // },
    {
      name: getTranslation('categories'),
      url: "/category-list",
      icon: "icon-list"
    },
    {
      name: getTranslation('tags'),
      url: "/tag-list",
      icon: "icon-tag"
    },
    {
      title: true,
      name: getTranslation('user'),
      class: "",
      wrapper: {
        element: "",
        attributes: {}
      }
    },
    {
      name: getTranslation('users'),
      url: "/user-list",
      icon: "fa fa-address-card"
    },
    {
      name: getTranslation('authors'),
      url: "/author-list",
      icon: "fa fa-pencil-square-o"
    },
    {
      title: true,
      name: getTranslation('other'),
      class: "",
      wrapper: {
        element: "",
        attributes: {}
      }
    },
    {
      name: getTranslation('pages'),
      url: "/page-list",
      icon: "fa fa-copy"
    },
    {
      name: getTranslation('menu'),
      url: "/menu-list",
      icon: "fa fa-bars"
    },
    {
      name: getTranslation('ads'),
      url: "/ads-list",
      icon: "fa fa-shopping-cart"
    },
    {
      name: getTranslation('options'),
      url: '/options',
      icon: 'fa fa-gear'
    },
    {
      name: getTranslation('logs'),
      url: '/log-list',
      icon: 'fa fa-history'
    },
    {
      name: getTranslation('terms'),
      url: '/term-list',
      icon: 'fa fa-file-o'
    },
  ]
}
